import axios from 'axios'
import { ADMIN_API_URL } from 'const'

const BASE_URL = `${ADMIN_API_URL}/clusters`

export const getClusterData = async (regenerate = false) => {
  try {
    const { data } = await axios.get(`${BASE_URL}`, {
      params: {
        regenerate: regenerate, // Pass the regenerate param as a query string
      },
    })
    return data
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}

// New function to get reclips by cluster index
export const getReclipsByCluster = async (clusterIndex) => {
  try {
    const { data } = await axios.get(`${BASE_URL}/${clusterIndex}`) // Call API with cluster index
    return data // Return the response data, which should include the reclips
  } catch (err) {
    throw new Error(err.response?.data?.detail || err.message)
  }
}
