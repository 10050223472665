import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import SharePlayer from 'components/core/SharePlayer'
// import Button from '@mui/material/Button'

const SharePlayerModal = () => {
  const {
    isOpen,
    params: { shareCode },
  } = useModal('sharePlayer')
  const { closeModal } = useModalsActions()
  const [ignoreTrim, setIgnoreTrim] = useState(false)

  useEffect(() => {
    setIgnoreTrim(false)
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={closeModal.bind(null, 'sharePlayer')}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {shareCode && (
          <Box
            sx={{
              height: '750px',
              width: '420px',
            }}
          >
            <SharePlayer
              key={ignoreTrim}
              shareCode={shareCode}
              ignoreTrim={ignoreTrim}
            />
          </Box>
        )}
        {/* {shareCode && !ignoreTrim && (
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => setIgnoreTrim(true)}
            sx={{ mt: 1 }}
          >
            view untrimmed
          </Button>
        )} */}
      </Box>
    </Dialog>
  )
}

export default SharePlayerModal
