import ConfirmPublish from './ConfirmPublish'
import Player from './Player'
import PickEditor from './PickEditor'

const Modals = () => {
  return (
    <>
      <ConfirmPublish />
      <Player />
      <PickEditor />
    </>
  )
}

export default Modals
