import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card' // Added for better formatting
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import useModalsActions from 'store/actions/modals'
import api from 'services/api'

const Clusters = () => {
  const { openModal } = useModalsActions() // Add this line to get the modal actions

  const [data, setData] = useState({ clusters: [] }) // Initialize with empty clusters array to prevent errors
  const [hoveredCluster, setHoveredCluster] = useState(null)
  const [clips, setClips] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [regenerating, setRegenerating] = useState(false)

  useEffect(() => {
    const fetchClusters = async (regenerate = false) => {
      setLoading(true) // Show the loader during the initial fetch
      try {
        const response = await api.clusters.getClusterData(regenerate)
        if (response.clusters) {
          console.log('Fetched clusters:', response.clusters)
          setData(response) // Set the clusters data
        } else {
          console.log('No clusters found, setting fallback data.')
          setData({ clusters: [] }) // Fallback to empty array if "clusters" is missing
        }
      } catch (err) {
        console.error('Error fetching clusters:', err)
        if (!regenerate) {
          console.log('Retrying with regenerate=true')
          fetchClusters(true) // Retry with regenerate=true
        } else {
          setError('Failed to fetch and regenerate clusters')
        }
      } finally {
        setLoading(false) // Hide the loader after fetch completes
      }
    }

    // Initial fetch call
    fetchClusters()
  }, [])

  const handleRegenerateClusters = async () => {
    console.log('Regenerate button clicked')
    setRegenerating(true) // Show loading state for regenerate

    try {
      const refreshedClusters = await api.clusters.getClusterData(true) // Trigger regeneration with regenerate=true
      console.log('Clusters regenerated successfully')
      setData(refreshedClusters) // Update clusters with new data
    } catch (err) {
      console.error('Failed to regenerate clusters:', err) // Log errors
      setError('Failed to regenerate clusters')
    } finally {
      setRegenerating(false) // Stop loading state after completion
    }
  }

  const handleMouseEnter = async (cluster) => {
    setHoveredCluster(cluster.title)
    setLoading(true)
    setError(null)

    try {
      const response = await api.clusters.getReclipsByCluster(cluster.index)
      setClips(response.reclips) // Update with actual clips from the API response
    } catch (err) {
      setError('Failed to fetch clips')
      setClips([])
    } finally {
      setLoading(false)
    }
  }

  // Function to handle clicking on a clip to play
  const handleClipClick = (source_id) => {
    console.log('Opening modal for source_id:', source_id)
    openModal('reclipPlayer', { source_id }) // Open the modal and pass the source_id
  }

  if (!data.clusters || data.clusters.length === 0) {
    return <Typography>Loading clusters...</Typography> // Handle loading state gracefully
  }

  return (
    <Box sx={{ height: '100%', width: '100%', p: 4, display: 'flex' }}>
      {/* Cluster Column */}
      <Box
        sx={{
          width: '30%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          pr: 3,
          overflowY: 'scroll',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 3,
            width: '100%',
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: 700 }}>
            Clusters
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegenerateClusters}
            sx={{ ml: 2 }} // Ensure proper margin from the title
          >
            Regenerate Clusters
          </Button>
        </Box>
        {data.clusters.map((cluster) => (
          <Button
            key={cluster.index}
            variant="outlined"
            onMouseEnter={() => handleMouseEnter(cluster)}
            sx={{
              width: '100%',
              textAlign: 'left',
              p: 3,
              mb: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              borderRadius: 2,
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.05)',
              },
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              {cluster.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
              {cluster.description}
            </Typography>
          </Button>
        ))}
      </Box>

      {/* Clips Column (Sticky for better alignment) */}
      <Box sx={{ flex: 1, ml: 4, position: 'sticky', top: 0 }}>
        {regenerating && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 2,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {!regenerating && hoveredCluster && (
          <>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
              Clips for {hoveredCluster}
            </Typography>

            {loading ? (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Loading clips...
              </Typography>
            ) : error ? (
              <Typography variant="body2" sx={{ color: 'error.main' }}>
                {error}
              </Typography>
            ) : clips.length > 0 ? (
              clips.map((clip, index) => (
                <Card
                  key={index}
                  sx={{
                    mb: 2,
                    p: 2,
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                  onClick={() => handleClipClick(clip.source_id)} // Handle click on clip
                >
                  {/* Cover photo on the left */}
                  {clip.cover_photo?.url && (
                    <CardMedia
                      component="img"
                      sx={{
                        width: 100, // Adjust the width as needed
                        height: 200, // Adjust the height as needed to maintain aspect ratio
                        borderRadius: 2, // Optional: rounding the corners
                        objectFit: 'cover', // Ensures the image covers the container without distortion
                        mr: 2, // Add margin to the right of the image
                      }}
                      image={clip.cover_photo.url}
                      alt={`Cover for ${clip.title_auto}`}
                    />
                  )}

                  {/* Text content (title and transcript) on the right */}
                  <Box sx={{ flex: 1 }}>
                    <CardContent sx={{ p: 0 }}>
                      <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        {clip.title_auto || 'Untitled'}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: 'text.secondary', mt: 1 }}
                      >
                        {clip.transcription.text || 'No transcript available'}
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              ))
            ) : (
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                No clips available
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default Clusters
