import { useEffect, useState, useCallback } from 'react'
import api from 'services/api'
import Loader from 'components/core/Loader'
import { ADMIN_API_URL } from 'const'
import { useAuth } from 'store/selectors'
import Player from 'components/core/Player'
import useToast from 'hooks/useToast'

const ShareLoader = ({ shareCode, ignoreTrim = false }) => {
  const [share, setShare] = useState(null)
  const [audioUrl, setAudioUrl] = useState(null)
  const [error, setError] = useState(null)
  const { token } = useAuth()
  const toast = useToast()
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    if (!shareCode) return setError('Share code not provided.')

    api.shares
      .getShare(shareCode)
      .then(setShare)
      .catch((e) => setError(e.message))
  }, [shareCode])

  useEffect(() => {
    if (!share) return

    const audioUrl = `${ADMIN_API_URL}/shares/code/${share.code}/audio`
    fetch(audioUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          return response.json().then((data) => {
            return Promise.reject(data?.detail)
          })
        } else return response.blob()
      })
      .then((blob) => {
        const blobUrl = URL.createObjectURL(blob)
        setAudioUrl(blobUrl)
      })
      .catch((err) => setError(err))
  }, [share, token, ignoreTrim])

  const onSave = useCallback(async () => {
    if (!share) return

    if (share.video_url) {
      window.open(share.video_url, '_blank')
      return
    }

    setIsSaving(true)

    try {
      const videoUrl = await api.shareVideos.getShareVideo(share.code)
      window.open(videoUrl, '_blank')
    } catch (e) {
      toast({
        severity: 'error',
        message: e.toString(),
      })
    } finally {
      setIsSaving(false)
    }
  }, [share, toast])

  if (!share || !audioUrl) return <Loader error={error} />

  // turn the share into a playable reclip
  const reclip = share.reclip
  reclip.transcription = share.transcription
  reclip.start = reclip.start_time.value
  reclip.end = reclip.end_time.value

  return (
    <Player
      reclip={reclip}
      audioUrl={audioUrl}
      trimmed={!ignoreTrim}
      onSave={onSave}
      isSaving={isSaving}
    />
  )
}

export default ShareLoader
