import { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import Box from '@mui/material/Box'
import useModalsActions from 'store/actions/modals'
import { useModal } from 'store/selectors'
import ReclipPlayer from 'components/core/ReclipPlayer'
// import Button from '@mui/material/Button'

const ReclipPlayerModal = () => {
  const {
    isOpen,
    params: { source_id },
  } = useModal('reclipPlayer')
  console.log('Modal state:', { isOpen, source_id })
  const { closeModal } = useModalsActions()
  const [ignoreTrim, setIgnoreTrim] = useState(false)

  useEffect(() => {
    setIgnoreTrim(false)
  }, [isOpen])

  return (
    <Dialog open={isOpen} onClose={closeModal.bind(null, 'reclipPlayer')}>
      <Box
        sx={{
          backgroundColor: 'background.default',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {source_id && (
          <Box
            sx={{
              height: '750px',
              width: '420px',
            }}
          >
            <ReclipPlayer
              key={ignoreTrim}
              source_id={source_id}
              ignoreTrim={ignoreTrim}
            />
          </Box>
        )}
        {/* {source_id && !ignoreTrim && (
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => setIgnoreTrim(true)}
            sx={{ mt: 1 }}
          >
            view untrimmed
          </Button>
        )} */}
      </Box>
    </Dialog>
  )
}

export default ReclipPlayerModal
